import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import Recaptcha from 'react-google-recaptcha'
import { Text as CommonText } from './common/typography'
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  BLUE_COLOR,
  BLACK_COLOR_RGB,
} from './common/color'
import bp from './common/breakpoints'
import { MainButton } from './common/button'
import { Link } from 'gatsby'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core'
import { display } from 'styled-system'

const RECAPTCHA_KEY =
  process.env.NODE_ENV === 'development'
    ? null
    : process.env.GATSBY_RECAPTCHA_KEY

const ContactWrapper = styled.div`
  padding-bottom: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Container = styled(motion.div)`
  display: grid;
  position: relative;
  z-index: 1;
  grid-template-rows: 1fr 2fr;
  row-gap: 4rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: transparent;
  ${bp[3]} {
    height: 100%;
    row-gap: unset;
    grid-template-rows: unset;
    grid-template-columns: 2fr 3fr;
    width: 100%;
    max-width: 1320px;
    padding-top: 100px;
  }
`

const Details = styled(motion.div)`
  display: grid;
  row-gap: 1.5rem;
  align-content: flex-start;
`
const Heading = styled.h4`
  margin: 0;
  font-size: 1.5rem;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
  font-family: 'source code pro';
`
const Text = styled(CommonText)`
  font-family: 'Manrope';
  color: ${BLACK_COLOR_RGB(0.6)};
  font-weight: 400;
  font-size: 12px;
`

const Form = styled(motion.form)`
  height: fit-content;
  display: grid;
  row-gap: 2rem;
  ${bp[3]} {
    height: 100%;
  }
`
const Label = styled(motion.label)`
  display: grid;
  row-gap: 0.75rem;
  font-family: 'source code pro';
  color: ${BLACK_COLOR};
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  max-height: 3rem;
  @media screen and (max-width: 598px) {
    max-height: ${(props) => (props.big && '5rem') || '3rem'};
  }
`
const Input = styled.input`
  color: ${BLACK_COLOR};
  background-color: ${BLACK_COLOR};
  color: ${WHITE_COLOR};
  border-radius: 4px;
  height: 2.5rem;
  padding: 0px 1rem;
  border-bottom: 1px solid ${BLACK_COLOR_RGB(0.8)};

  outline-color: ${WHITE_COLOR};
`
const HeadingWhite = styled.h1`
  font-size: 3rem;
  max-width: 600px;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0rem;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 4rem;
  }
`
const ContactFooter = styled.div`
  widht: 100%;
  display: flex;
  justify-content: space-between;
`
const DesignByAlio = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Manrope';
  font-size: 0.75rem;
  color: ${BLACK_COLOR};
`
const PrivacyPolicy = styled(Link)`
  margin: 0;
  padding: 0;
  font-family: 'Manrope';
  font-size: 0.75rem;
  color: ${BLUE_COLOR};
  cursor: pointer;
`

const checkboxLabel = {
  fontSize: '0.75rem',
  fontFamily: 'source code pro',
  color: '${BLACK_COLOR}',
  fontWeight: '600',
}

const callDate = {
  fontSize: '0.75rem',
  fontFamily: 'source code pro',
  color: '${BLACK_COLOR}',
  fontWeight: '600',
  height: '1.5rem',
}

const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 2,
    },
  },
}

const ContactForm = () => {
  const [isThankyou, setIsThankyou] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [location, setLocation] = useState('')
  const [projectDescription, setProjectDescription] = useState('')
  const [techStack, setTechStack] = useState('')
  const [timeline, setTimeline] = useState('')
  const [date, setDate] = useState('')
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    projectDescription: '',
    techStack: '',
    timeline: '',
    date: '',
    'g-recaptcha-response': '',
  })
  const { search } = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(search)
    const message = params.get('message')
    if (message === 'thank-you') {
      setIsThankyou(true)
    } else {
      setIsThankyou(false)
    }
  }, [search])

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('onscreen')
    }
  }, [controls, inView])

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleRecaptchaChange = (value) => {
    setFormData((prev) => ({ ...prev, 'g-recaptcha-response': value }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmitHubspot = (e) => {
    const formElement = e.target
    e.preventDefault()

    try {
      const xhr = new XMLHttpRequest()
      const url =
        'https://api.hsforms.com/submissions/v3/integration/submit/5727045/d29faeaf-87ea-417f-9386-02d84b16f583'
      const data = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'country',
            value: location,
          },
          {
            name: '0-2/description',
            value: projectDescription,
          },
          {
            name: '0-2/tech_stack',
            value: techStack,
          },
          {
            name: '0-2/timeline',
            value: timeline,
          },
          {
            name: 'set_up_call',
            value: date,
          },
        ],
        context: {
          pageUri: 'www.alioit.com',
          pageName: 'Alio IT',
        },
      }

      const finalData = JSON.stringify(data)

      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(finalData)

      setIsThankyou(true)
      setEmail('')
      setFirstName('')
      setLastName('')
      setLocation('')
      setProjectDescription('')
      setTechStack('')
      setTimeline('')
      setDate('')
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formElement = e.target

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': formElement.getAttribute('name'),
          ...formData,
        }),
      })

      navigate(formElement.getAttribute('action'))
    } catch (err) {
      console.error(err.message)
    }
  }

  return (
    <ContactWrapper>
      <Container
        id="contact"
        initial={'offscreen'}
        ref={ref}
        animate={controls}
        transition={{ staggerChildren: 0.3 }}
      >
        <Details variants={CardAnimate}>
          <HeadingWhite id="contactTitle">Contact us</HeadingWhite>
          <Heading>Headquarters</Heading>
          <Text>Contact Us</Text>

          <div>
            <Heading>USA</Heading>
            <Text>
              Phone: 520 367 2544
              <br />
              9040 S Rita Rd #1270
              <br />
              Tucson, AZ 85747
            </Text>
          </div>

          <div>
            <Heading>Mexico</Heading>
            <Text>
              Garmendia #202-I, Col.Centro
              <br />
              Hermosillo, Son. 83000
              <br />
              Email: info@alioit.com
              <br />
              Phone: +52 662 437 5190
            </Text>

            <div
              style={{
                marginRight: '30px',
                marginTop: '10px',
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: 1,
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3380.199885885359!2d-110.8088969!3d32.090883!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d663d8bfe2f661%3A0x3b0b36ff3a1d0eda!2sALIO%20IT!5e0!3m2!1sen!2smx!4v1684943078298!5m2!1sen!2smx"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <Text>Follow Us</Text>
        </Details>
        <Form
          action="/?message=thank-you#contact"
          name="contact"
          method="POST"
          data-netlify-recaptcha="true"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmitHubspot}
          variants={CardAnimate}
          transition={{ staggerChildren: 0.3 }}
        >
          {isThankyou && (
            <div>
              <Heading>Thanks for contacting us.</Heading>
              <Text>
                Your message has been successfully sent. We will contact you
                very soon!
              </Text>
            </div>
          )}
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Label>
            Email *
            <Input
              name="email"
              type="email"
              required
              onBlur={handleChange}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Label>
          <Label>
            First name *
            <Input
              name="firstName"
              required
              onBlur={handleChange}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </Label>
          <Label>
            Last name *
            <Input
              name="lastName"
              required
              onBlur={handleChange}
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </Label>
          <Label>
            Country/Region *
            <Input
              name="location"
              required
              onBlur={handleChange}
              onChange={(e) => setLocation(e.target.value)}
              value={location}
            />
          </Label>
          <Label>
            Project Description *
            <Input
              name="projectDescription"
              required
              onBlur={handleChange}
              onChange={(e) => setProjectDescription(e.target.value)}
              value={projectDescription}
            />
          </Label>
          <Label>
            Tech Stack
            <Input
              name="techStack"
              required
              onBlur={handleChange}
              onChange={(e) => setTechStack(e.target.value)}
              value={techStack}
            />
          </Label>
          <Label big>
            Timeline *
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="timeline"
                onBlur={handleChange}
                row
                onChange={(e) => setTimeline(e.target.value)}
                value={timeline}
              >
                <FormControlLabel
                  value="1 - 3 months"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography style={checkboxLabel}>
                      {' '}
                      1 - 3 months{' '}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="3 - 6 months"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography style={checkboxLabel}>
                      {' '}
                      3 - 6 months{' '}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="1 year"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography style={checkboxLabel}> 1 year </Typography>
                  }
                />
                <FormControlLabel
                  value="More than one year"
                  control={<Radio size="small" color="default" />}
                  label={
                    <Typography style={checkboxLabel}>
                      {' '}
                      More than one year{' '}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Label>
          <Label>
            Set up call
            <input
              type="date"
              name="date"
              onBlur={handleChange}
              style={callDate}
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
          </Label>
          {RECAPTCHA_KEY && (
            <Recaptcha
              sitekey={RECAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          )}
          <MainButton icon={'send'} type={'submit'}>
            Send Message
          </MainButton>
        </Form>
      </Container>
      <ContactFooter>
        <DesignByAlio>Design by ALIO IT</DesignByAlio>
        <PrivacyPolicy to={'/privacy-policy'}>@PrivacyPolicy</PrivacyPolicy>
      </ContactFooter>
    </ContactWrapper>
  )
}

export default ContactForm
