import React from 'react'
export const CustomTooltip = (props) => {
  const container = {
    color: 'rgb(77, 77, 77)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 8px 0px',
    maxWidth: 300,
    padding: '10px 10px 5px',
    minWidth: 320,
  }
  const title = {
    margin: '4px',
    fontSize: '24px'
  }
  const description = {
    margin: '4px'
  }
  const primaryButton = {
    padding: '4px 16px',
    textAlign: 'center',
    border: '1px solid rgb(2, 92, 83)',
    borderRadius: '3px',
    backgroundColor: 'rgb(0, 132, 255)',
    color: 'rgb(255, 255, 255)',
    fontSize: '14px',
    margin: '4px auto 4px 4px'
  }
  const secondaryButton = {
    padding: '4px 16px',
    textAlign: 'center',
    border: '1px solid rgb(2, 92, 83)',
    borderRadius: '3px',
    backgroundColor: 'transparent',
    color: 'rgb(2, 92, 83)',
    fontSize: '14px',
    margin: '4px auto 4px 4px'
  }


  return <div style={container}>
    <div style={title}>{props.stepContent.title}</div>
    <div style={description}>{props.stepContent.description}</div>
    {props.stepContent.disableClose ? null : <button style={secondaryButton} onClick={props.close}>Ok</button>}
    {props.stepContent.disableNext ? null : <button style={primaryButton} onClick={props.next}>Next</button>}
  </div >
}