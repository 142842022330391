import React from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { PRIMARY_COLOR, WHITE_COLOR, BLACK_COLOR } from './common/color'
import Compass from './compass'
import Coffee from '../images/assets/coffe.inline.svg'
import FloatingBalls from '../images/assets/floatingBalls.inline.svg'
import bp from './common/breakpoints'
import { MainButton, SecondaryButton } from './common/button'
import Mouse from '../images/assets/mouse.inline.svg'
import './components.css'

const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-top: 15px;
  ${bp[2]} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 10vh;
    row-gap: 6vh;
  }
  ${bp[4]} {
    padding-top: 75px;
  }
`
const HeroMobileFooter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${bp[2]} {
    display: none;
  }
`
const OtherButtons = styled.div`
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
`
const HeroDesktopFooter = styled.div`
  position: relative;
  width: 100%;
  max-height: 10vh;
  grid-column: 1/3;
  grid-row: 1/3;
  display: none;
  justify-content: space-between;
  align-items: flex-end;
  // &:before {
  //   content: "";
  //   width: 100%;
  //   height: 1px;
  //   background: #9C9C9C;
  //   position: absolute;
  //   top: 7rem;
  // }
  ${bp[2]} {
    display: flex;
  }
`
const Comodin = styled.div`
  display: flex;
  gap: 0.4rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const svgStyles = (props) =>
  css(`
  #Scroll {
    animation: ScrollAnimation 2s ease-out infinite;
  @keyframes ScrollAnimation {
    0% {
      transform-origin: center;
      transform:  translateY(-5px);
      opacity: 0;
    }
    50% {
      transform-origin: center;
      transform:translateY(0px);
      opacity: 1;
    }
    100% {
      transform-origin: center;
      transform:translateY(10px) ;
      opacity: 0;
    }
  }
`)
const WatchTheComercial = styled.p`
  font-family: 'Manrope';
  font-size: 12px;
  font-weight: 600;
  opacity: 0.6;
  color: ${BLACK_COLOR};
  letter-spacing: 0.025em;
`

const Achivments = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 8rem;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #9c9c9c;
    position: absolute;
    margin-top: 1rem;
  }
`

const Achivment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const AchivmentCount = styled.p`
  font-family: 'Manrope';
  font-size: 40px;
  font-weight: 700;
  color: ${BLACK_COLOR};
  text-align: right;
  padding: 0px;
  margin: 0px;
  z-index:10;
`
const AchivmentTitle = styled.p`
  font-family: 'Source Code Pro';
  font-size: 12px;
  font-weight: 600;
  color: ${BLACK_COLOR};
  text-transform: uppercase;
  letter-spacing: 0.025em;
  z-index:10;
`
const StartAProjectLink = styled(Link)`
  display: none;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-family: 'Source Code Pro' !important;
  background: ${BLACK_COLOR};
  border-radius: 4px;
  margin-top: 14px;
  @media (max-width: 1480px) {
    padding: 0rem 0;
  }
  ${bp[2]} {
    display: flex;
  }
`
const IconContainer = styled.div`
  padding: 12px 12px;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${PRIMARY_COLOR};
`
const StartAProjectText = styled.p`
  display: flex;
  color: white;
  text-transform: uppercase;
  font-family: 'Source Code Pro' !important;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: ${WHITE_COLOR};
  letter-spacing: 0.115em;
  padding: 0px 24px;
  margin-top: 14px;
  &:hover {
    color: ${PRIMARY_COLOR};
  }
`
const AchivmentsCounter = [
  {
    title: 'Years of experience',
    count: '12+',
  },
  {
    title: 'Completed projects',
    count: '100+',
  },
  {
    title: 'Satisfied clients',
    count: '20+',
  },
]

const Hero = () => {
  return (
    <Container>
      <HeroDesktopFooter>
        <Comodin>
          <MainButton to={'/#contact'} icon={'coffee'} id="contactButton">
            Contact Us
          </MainButton>
        </Comodin>
        <Achivments>
          {AchivmentsCounter.map((item, index) => (
            <Achivment>
              <AchivmentCount>{item.count}</AchivmentCount>
              <AchivmentTitle>{item.title}</AchivmentTitle>
            </Achivment>
          ))}
        </Achivments>
      </HeroDesktopFooter>
      <HeroMobileFooter>
        <MainButton to={'/#contact'} icon={'coffee'}>
          Start a project
        </MainButton>
        <OtherButtons>
          <SecondaryButton to={'/careers-opportunity'}>
            Career Opportunities
          </SecondaryButton>
          <SecondaryButton to={'/community'}>Community</SecondaryButton>
        </OtherButtons>
      </HeroMobileFooter>
    </Container>
  )
}

export default Hero
