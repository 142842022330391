import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import bp from '../components/common/breakpoints';
import { BLACK_COLOR } from './common/color';
import YouTube from 'react-youtube';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import BackgroundPattern from '../images/assets/backgroundPatterMin.inline.svg';
import YoutubeSVG from '../images/icons/social-media/youtube-red.svg';

const Wrapper = styled(motion.div)`
  height: calc(100vh - 116px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled(motion.div)`
  display: flex;
  height: 100%;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  ${bp[3]} {
    flex-direction: row;
  }
`;

const Container = styled(motion.div)`
  display: grid;
  grid-template-column: 1fr;
  align-items: top;
  column-gap: 2rem;
  row-gap: 2rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  ${bp[3]} {
    width: 100%;
  }
`;

const VisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  ${bp[3]} {
    width: 100%;
  }
`;

const HeadingWhite = styled(motion.h1)`
  font-size: 2.5rem;
  width: 100%;
  text-align: left;
  line-height: 1.50;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 3rem;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 4rem;
    line-height: 1.8;
  }
`;

const BackgroundContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbnailIconStyle = {
  position: "absolute",
  top: "calc(90% - 145px)",
  left: "0",
  width: "100%",
  height: "25%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ThumbnailImg = {
  width: "100%",
  height: "auto",
  cursor: "pointer",
  maxHeight: "65vh",
};

const VideoContainer = styled.div`
  width: 640px;
  height: 390px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    iframe {
      width: 100%;
      height: auto;
    }
  }
`;

const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2,
      delay: 0.3,
    },
  },
};

const AlioVision = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("onscreen");
    }
  }, [controls, inView]);

  const [showComponent, setShowComponent] = useState(false);
  const thumbnailUrl = "https://img.youtube.com/vi/OlVomFVT8-o/hqdefault.jpg";
  const handleClick = () => {
    setShowComponent(true);
  };

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Wrapper initial={"offscreen"} ref={ref} animate={controls} transition={{ staggerChildren: 0.3 }}>
      <BackgroundContainer variants={CardAnimate}>
        <BackgroundPattern />
      </BackgroundContainer>
      <Content variants={CardAnimate}>
        <HeadingWhite>Your remote team,but...<br /> <span style={{ color: '#EF761F' }}> closer </span> </HeadingWhite>
        <Container>
          <VisionContainer onClick={handleClick}>
            <img src={thumbnailUrl} alt="Thumbnail" hidden={showComponent} style={ThumbnailImg} />
            <div hidden={showComponent}>
              <YoutubeSVG style={ThumbnailIconStyle} />
            </div>
            {showComponent && (
              <VideoContainer>
                <YouTube videoId="OlVomFVT8-o" opts={opts} />
              </VideoContainer>
            )}
          </VisionContainer>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default AlioVision;
