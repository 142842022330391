import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

// *************** ContactForm
import bp from '../common/breakpoints'
import styled from '@emotion/styled'
import { MainButton } from '../common/button'
import Recaptcha from 'react-google-recaptcha'
import { motion } from 'framer-motion'
import { Text as CommonText } from '../common/typography'
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
  BLACK_COLOR_RGB,
} from '../common/color'
import {
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
} from '@material-ui/core'

const RECAPTCHA_KEY =
  process.env.NODE_ENV === 'development'
    ? null
    : process.env.GATSBY_RECAPTCHA_KEY

const Form = styled(motion.form)`
  height: fit-content;
  display: grid;
  row-gap: 2rem;
  ${bp[3]} {
    height: 100%;
  }
`
const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 2,
    },
  },
}

const Heading = styled.h4`
  margin: 0;
  font-size: 1.5rem;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
  font-family: 'source code pro';
`

const callDate = {
  fontSize: '0.75rem',
  fontFamily: 'source code pro',
  color: '${BLACK_COLOR}',
  fontWeight: '600',
  height: '1.5rem',
}

const Label = styled(motion.label)`
  display: grid;
  row-gap: 0.75rem;
  font-family: 'source code pro';
  color: ${BLACK_COLOR};
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  max-height: 3rem;
  @media screen and (max-width: 1000px) {
    max-height: ${(props) => (props.big && '5rem') || '3rem'};
  }
`

const checkboxLabel = {
  fontSize: '0.75rem',
  fontFamily: 'source code pro',
  color: '${BLACK_COLOR}',
  fontWeight: '600',
}

const Input = styled.input`
  color: ${BLACK_COLOR};
  background-color: ${BLACK_COLOR};
  color: ${WHITE_COLOR};
  border-radius: 4px;
  height: 2.5rem;
  padding: 0px 1rem;
  border-bottom: 1px solid ${BLACK_COLOR_RGB(0.8)};

  outline-color: ${WHITE_COLOR};
`

const Text = styled(CommonText)`
  font-family: 'Manrope';
  color: ${BLACK_COLOR_RGB(0.6)};
  font-weight: 400;
  font-size: 12px;
`

const ContactForm = () => {
  const [isThankyou, setIsThankyou] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [location, setLocation] = useState('')
  const [projectDescription, setProjectDescription] = useState('')
  const [techStack, setTechStack] = useState('')
  const [timeline, setTimeline] = useState('')
  const [date, setDate] = useState('')

  const handleSubmitHubspot = (e) => {
    e.preventDefault()

    try {
      const xhr = new XMLHttpRequest()
      const url =
        'https://api.hsforms.com/submissions/v3/integration/submit/5727045/d29faeaf-87ea-417f-9386-02d84b16f583'
      const data = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'country',
            value: location,
          },
          {
            name: '0-2/description',
            value: projectDescription,
          },
          {
            name: '0-2/tech_stack',
            value: techStack,
          },
          {
            name: '0-2/timeline',
            value: timeline,
          },
          {
            name: 'set_up_call',
            value: date,
          },
        ],
        context: {
          pageUri: 'www.alioit.com',
          pageName: 'Alio IT',
        },
      }

      const finalData = JSON.stringify(data)

      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(finalData)

      setIsThankyou(true)
      setEmail('')
      setFirstName('')
      setLastName('')
      setLocation('')
      setProjectDescription('')
      setTechStack('')
      setTimeline('')
      setDate('')
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleRecaptchaChange = (value) => {}

  const handleChange = (e) => {}

  return (
    <>
      <Form
        action="/?message=thank-you#contact"
        name="contact"
        method="POST"
        data-netlify-recaptcha="true"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={handleSubmitHubspot}
        variants={CardAnimate}
        transition={{ staggerChildren: 0.3 }}
      >
        {isThankyou && (
          <div>
            <Heading>Thanks for contacting us.</Heading>
            <Text>
              Your message has been successfully sent. We will contact you very
              soon!
            </Text>
          </div>
        )}
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <Label>
          Email *
          <Input
            name="email"
            type="email"
            required
            onBlur={handleChange}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Label>
        <Label>
          First name *
          <Input
            name="firstName"
            required
            onBlur={handleChange}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
        </Label>
        <Label>
          Last name *
          <Input
            name="lastName"
            required
            onBlur={handleChange}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </Label>
        <Label>
          Country/Region *
          <Input
            name="location"
            required
            onBlur={handleChange}
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />
        </Label>
        <Label>
          Project Description *
          <Input
            name="projectDescription"
            required
            onBlur={handleChange}
            onChange={(e) => setProjectDescription(e.target.value)}
            value={projectDescription}
          />
        </Label>
        <Label>
          Tech Stack
          <Input
            name="techStack"
            required
            onBlur={handleChange}
            onChange={(e) => setTechStack(e.target.value)}
            value={techStack}
          />
        </Label>
        <Label big>
          Timeline *
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="timeline"
              onBlur={handleChange}
              row
              onChange={(e) => setTimeline(e.target.value)}
              value={timeline}
            >
              <FormControlLabel
                value="1 - 3 months"
                control={<Radio size="small" color="default" />}
                label={
                  <Typography style={checkboxLabel}> 1 - 3 months </Typography>
                }
              />
              <FormControlLabel
                value="3 - 6 months"
                control={<Radio size="small" color="default" />}
                label={
                  <Typography style={checkboxLabel}> 3 - 6 months </Typography>
                }
              />
              <FormControlLabel
                value="1 year"
                control={<Radio size="small" color="default" />}
                label={<Typography style={checkboxLabel}> 1 year </Typography>}
              />
              <FormControlLabel
                value="More than one year"
                control={<Radio size="small" color="default" />}
                label={
                  <Typography style={checkboxLabel}>
                    {' '}
                    More than one year{' '}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Label>
        <Label>
          Set up call
          <input
            type="date"
            name="date"
            onBlur={handleChange}
            style={callDate}
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
        </Label>
        {RECAPTCHA_KEY && (
          <Recaptcha sitekey={RECAPTCHA_KEY} onChange={handleRecaptchaChange} />
        )}
        <MainButton icon={'send'} type={'submit'}>
          Send Message
        </MainButton>
      </Form>
    </>
  )
}
// ***************

const ContactModal = (props) => {
  const { show, setShow } = props

  const handleClose = () => {
    setShow(false)
  }

  const handleSummit = () => {
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="special_contact-modal"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Contact Form</Modal.Title>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="contact-modal-button-close"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ContactForm />
        </Modal.Body>
        <Modal.Footer>
          <div className="center">
            <Button
              variant="secondary"
              className="contact-modal-button-contact"
              onClick={handleSummit}
            >
              <b>Close</b>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ContactModal
