import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import './modalService.css'

import ArrowLeft from '../../images/majesticons/arrow-left.svg'
import ArrowRight from '../../images/majesticons/arrow-right.svg'

import ContactModal from '../ContactModal'
import { Walktour, CardinalOrientation } from 'walktour'
import { CustomTooltip } from '../../components/tourCustomTooltip'
import Cookies from 'universal-cookie';

const ModalBody = (props) => {
  const { item, handleMoveLeft, handleMoveRight } = props

  return (
    <div className="body-container">
      <div className="body-fitcontents">
        <Button
          variant="secondary"
          className="body-fitcontents-button-icon"
          onClick={handleMoveLeft}
        >
          <ArrowLeft className="body-fitcontents-icon" />
        </Button>
      </div>
      <div>
        <div className="body-title">
          <div className="body-title-icon">{item.number}</div>
          <div className="body-title-text">{item.title}</div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.longdescription }} />
      </div>
      <div className="body-fitcontents">
        <Button
          variant="secondary"
          className="body-fitcontents-button-icon"
          onClick={handleMoveRight}
        >
          <ArrowRight className="body-fitcontents-icon" />
        </Button>
      </div>
    </div>
  )
}

const ModalService = (props) => {
  const { item, index, services, show, setShow } = props
  const [itemFromServices, setItemFromServices] = useState(null)
  const [indexMove, setIndexMove] = useState(null)
  const [showContact, setShowContact] = useState(false)
  const { logoBlack } = useStaticQuery(query)

  const handleClose = () => {
    setItemFromServices(null)
    setIndexMove(null)
    setShow(false)
  }

  const handleMoveLeft = () => {
    const pickedIndex = indexMove == null ? index : indexMove

    if (pickedIndex - 1 < 0) {
      setIndexMove(services.length - 1)
      setItemFromServices(services[services.length - 1])
    } else {
      setIndexMove(pickedIndex - 1)
      setItemFromServices(services[pickedIndex - 1])
    }
  }

  const handleMoveRight = () => {
    const pickedIndex = indexMove == null ? index : indexMove
    if (pickedIndex + 1 > services.length - 1) {
      setIndexMove(0)
      setItemFromServices(services[0])
    } else {
      setIndexMove(pickedIndex + 1)
      setItemFromServices(services[pickedIndex + 1])
    }
  }

  const handleContactUs = (e) => {
    setShowContact(true)
  }

  const cookies = new Cookies(null, { path: '/' });
  var startTour = true;
  if (cookies.get('Tour2') === 'Yes') {
    startTour = false;
  }
  cookies.set('Tour2', 'Yes');
  const [tourOpen, setTourOpen] = useState(startTour);
  const closeTour = (logic) => {
    setTourOpen(false);
    logic.close();
    const contactButon = document.getElementById('serviceModalContact');
    contactButon.click();
  }

  const closeOnClick = (logic, target) => {
    if (target === true) {
      closeTour(logic);
    } else {
      logic.next()
    }
  }

  const tourSteps = [
    {
      selector: '#serviceModalContact',
      title: '',
      description: 'Please contact us and also check out in "Community" our blog and podcast also we have case studies on the website for different industries.',
      disableNext: true,
      disableClose: false,
      nextOnTargetClick: true,
      orientationPreferences: [CardinalOrientation.NORTH],
      customNextFunc: (logic, fromTarget) => closeOnClick(logic, fromTarget),
      customTooltipRenderer: (logic) => <CustomTooltip {...logic} {...logic.stepContent} />,
      movingTarget: true,
    }
  ]

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="special_modal"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <GatsbyImage fixed={logoBlack.fixed} />
          </Modal.Title>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="modal-button-close"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ModalBody
            item={itemFromServices || item}
            handleMoveLeft={handleMoveLeft}
            handleMoveRight={handleMoveRight}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="center">
            <Button
              variant="secondary"
              className="modal-button-contact"
              onClick={handleContactUs}
            >
              <b id="serviceModalContact">Contact Us</b>
            </Button>
          </div>
        </Modal.Footer>
        <Walktour
          steps={tourSteps}
          isOpen={tourOpen}
          customCloseFunc={(logic) => closeTour(logic)}
          disableCloseOnClick
        />
      </Modal>
      <ContactModal show={showContact} setShow={setShowContact} />
    </>
  )
}

const query = graphql`
  query {
    logoBlack: imageSharp(
      fixed: { originalName: { eq: "alio-logo-black.png" } }
    ) {
      fixed(height: 24) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default ModalService
